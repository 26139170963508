import * as React from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import SEO from '../components/Seo';
import Title from '../components/Title';
import { clearAuthCookies } from '../lib/auth';

const Logout = (): React.ReactNode => {
  React.useEffect(() => {
    clearAuthCookies();
  }, []);

  return (
    <Grid container spacing={4} justify="center">
      <SEO title="Logout" />

      <Grid item xs={12}>
        <Title />
      </Grid>

      <Grid item xs={12}>
        <Typography align="center">
          Thank you for using Expencer. You are now logged out.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Button color="primary" component={Link} to="/" fullWidth>
          Back to homepage
        </Button>
      </Grid>
    </Grid>
  );
};
export default Logout;
